import useFetch from '../../../Components/CommonUtility/useFetch';
import { RepackBox } from '../../../Dash/RepackPage/Types/RepackBox';
import { RepackBoxType } from '../../../Dash/RepackPage/Types/RepackBoxType';
import { RepackCase } from '../../../Dash/RepackPage/Types/RepackCaseType';
import { RepackItem } from '../../../Dash/RepackPage/Types/RepackItem';
import { RepackTicket } from '../../../Dash/RepackPage/Types/RepackTicket';
import { Card } from '../../../Dash/SinglesPage/Types/CardType';
import FetchModule from '../../../Modules/FetchModule';
const fetchModule = new FetchModule();

export async function UpdateRepackBox(
    box: RepackBox, 
    items: RepackItem[], 
    user: any, //todo: create user type
    caseId?: string  
){
    const response = await fetchModule.postResponseBody("/Repack/update/box", "POST", 
    {
        UserId: user["id"],
        Body: {
            "Box": box,
            "Items": items,
            "RepackCaseId": caseId  
        },
        Guid: box.publicId
    });
    return response;    
}

export async function UpdateRepackCase(
    repackCase: RepackCase, 
    user:  any, //todo: create user type
){
    const response = await fetchModule.postResponseBody("/Repack/update/case", "POST", 
    {
        UserId: user["id"],
        Body: {
            "Case": repackCase
        },
        Guid: repackCase.publicId
    })
    return response    
}

export async function UpdateItem(
    user: any, //todo: create user type
    item?: RepackItem, 
){
    const response = await fetchModule.postResponseBody("/Repack/update/item", "POST", 
    {
        UserId: user["id"],
        Body: {
            "PublicId": item?.publicId,
            "RepackItem": item,
        }
    })
    return response    
}

export async function CreateStagedCards(
    item: Card, 
    user: any, //todo: create user type
    boxId: string,
){ 
    const response = await fetchModule.postResponseBody("/Repack/new/item", "POST", 
    {
        UserId: user["id"],
        Body: {
            "ProductId": item.productId,
            "RepackBoxId": boxId,
            "RepackItemType": "Card",   
            "CardId": item.publicId,
            "IsStaged": true        
        }
    })
    //TODO: should I also update the card to have a new status here, or when it is seelcted for repack?
    return response    
    
}

export async function CreateStagedTickets(
    item: RepackTicket, 
    user: any, //todo: create user type
    boxId: string,
){ 
    const response = await fetchModule.postResponseBody("/Repack/new/item", "POST", 
    {
        UserId: user["id"],
        Body: {
            "RepackBoxId": boxId,
            "RepackItemType": "Ticket",   
            "TicketId": item.publicId,
            "IsStaged": true        
        }
    })
    //TODO: should I also update the ticket to have a status here
    return response    
    
}

export async function CreateNewBox(
    user: any, //todo: create user type
    selectedBoxType: RepackBoxType,
    seriesId: string,
){
    return await fetchModule.postResponseBody("/Repack/new/box", "POST", {
        Body: {
            UserId: user["id"],
            RepackTypeId: selectedBoxType.publicId,
            SeriesId: seriesId,
            IsStaged: true,
        },
    });
}

//We delete the item, reset the status to "Available", and remove the publicId from Ticket/Card
export async function DeleteItem(
    item: RepackItem, 
    user: any, //todo: create user type
){ 
    const response = await fetchModule.postResponseBody("/Repack/delete/item", "DELETE", 
    {
        UserId: user["id"],
        Body: {
            "RepackItemId": item.publicId    
        }
    })
    return response    
    
}

export async function DeleteRepackBox(
    box: RepackBox, 
    user: any, //todo: create user type
){ 
    const response = await fetchModule.postResponseBody("/Repack/delete/box", "DELETE", 
    {
        UserId: user["id"],
        Body: {
            "RepackBoxId": box.publicId,
        }
    })
    return response    
}

export async function CreateNewItemType(repackItemTypeName: string, user: any) { //todo: create user type
  const response = await fetchModule.postResponseBody("/Repack/new/itemType", "POST", {
    UserId: user["id"],
    Body: {
      "RepackItemTypeName": repackItemTypeName,
    },
  });
  
  return response;
}

export async function GetBoxes(seriesId: string) {
    const response = useFetch(`/Repack/get/allstagedboxes/${seriesId}`);
    
    return response;
  }
