import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import {
  DataGridPro,
  GridColDef,
  GridRowId,
  GridRowParams,
  GridToolbarContainer,
} from "@mui/x-data-grid-pro";
import Button from "@mui/material/Button";
import { useSnackbar } from "../../../Contexts/SnackbarContext/SnackbarContext";
import { useUser } from "../../../Components/CommonUtility/UserContext";
import { useBuildRepacks } from "../../../Contexts/RepackContexts/BuildRepackContexts/BuildRepacksContext";
import { RepackBox } from "../Types/RepackBox";
import { RepackBoxType } from "../Types/RepackBoxType";
import {
  Autocomplete,
  Box,
  CircularProgress,
  Divider,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { RepackItem } from "../Types/RepackItem";
import {
  DeleteItem,
  CreateNewBox,
  DeleteRepackBox,
  UpdateRepackBox,
  UpdateItem,
} from "../../../Contexts/RepackContexts/BuildRepackContexts/BuildRepacksAPICalls";
import { RepackItemType } from "../Types/RepackItemType";
import CollapsiblePaper from "../../../Components/Common/CollapsiblePaper";
import { useRepackCreatorCard } from "../../../Contexts/RepackContexts/BuildRepackContexts/RepackPreviewContext";
import { RepackTicket } from "../Types/RepackTicket";
import { Card } from "../../SinglesPage/Types/CardType";

const StagedRepackPreview = () => {
  const [searchParams] = useSearchParams();
  const [expandedRowIds, setExpandedRowIds] = useState<GridRowId[]>([]);
  const [isAddingToCase, setIsAddingToCase] = useState(false);
  const [boxRows, setBoxRows] = useState<RepackBox[]>([]);
  const seriesId = searchParams.get("id") || "";
  const { newSnackbarMessage } = useSnackbar();
  const { user } = useUser();
  const { setTickets } = useBuildRepacks();
  const {
    boxTypes,
    setSelectedBox,
    selectedBox,
    itemTypes,
    boxes,
    setBoxes,
    setSelectedSeriesId,
    box,
    setBox,
    boxLoading,
    selectedCase,
    setAllTickets,
  } = useBuildRepacks();
  const { setRows } = useRepackCreatorCard();

  const [selectedBoxType, setSelectedBoxType] = useState<any>(
    boxTypes[0]?.id | 1
  );
  const [selectedItemType, setSelectedItemType] = useState<RepackItemType>({
    id: 0,
    name: "",
  });

  useEffect(() => {
    const newBoxRows = (boxes || []).map((b: RepackBox) => ({
      ...b,
    }));
    setBoxRows(newBoxRows);
  }, [boxes]);

  useEffect(() => {
    setSelectedSeriesId(seriesId);
  }, [expandedRowIds, setSelectedSeriesId, seriesId]);

  const mapRepackItemTypeName = (item: RepackItem) => {
    const type = itemTypes.find(
      (type: RepackItemType) => type.id === item.repackItemTypeId
    );
    return type ? type.name : "Unknown Type";
  };

  const mapRepackItemDifference = (item: RepackItem) => {
    if (item.repackCard) {
      const comp = Number(item.repackCard.comp || 0);
      const cost = Number(item.repackCard.totalCost || 0);
      return comp - cost;
    }
    return 0;
  };

  const handleSelectedBoxTypeChange = (event: SelectChangeEvent<number | ''>) => {
    const value = event.target.value;
    if (value !== '') {
      setSelectedBoxType(value as number);
    }
  };

  const handleUpdateRepackBox = async (updatedRow: RepackBox) => {
    try {
      const b = updatedRow ?? [];
      const items = b.items ?? [];
      const response = await UpdateRepackBox(updatedRow, items, user);

      if (response.ok) {
        newSnackbarMessage("Repack box updated successfully!", "success");
        const updatedBoxes = boxes?.map((rb: RepackBox) =>
          rb.id === updatedRow.id ? { ...rb, ...updatedRow } : rb
        );
        return updatedBoxes;
      } else {
        newSnackbarMessage("Failed to update repack box.", "error");
      }
    } catch (error) {
      console.error("Failed to update repack box:", error);
      newSnackbarMessage("Error updating repack box.", "error");
    }
  };

  const handleUpdateRepackItems = async (updatedRow: RepackItem) => {
    try {
      const row = {
        ...updatedRow,
        repackItemTypeId: selectedItemType?.id,
        repackItemType: selectedItemType,
      };
      const response = await UpdateItem(user, row);

      if (response.ok) {
        newSnackbarMessage("Repack Item updated successfully!", "success");
        const rData = await response.json();
        const rDataResult = await rData.result;
        return rDataResult;
      } else {
        newSnackbarMessage("Failed to update repack item.", "error");
      }
    } catch (error) {
      console.error("Failed to update repack item:", error);
      newSnackbarMessage("Error updating repack item.", "error");
    }
  };

  const handleDeleteItem = async (item: RepackItem, user: any) => {
    const response = await DeleteItem(item, user);
    if (response.ok) {
      const updatedBoxData = await response.json();
      const updatedBox = updatedBoxData.result;

      setBox(updatedBox);
      setSelectedBox(updatedBox);

      setBoxes((prevBoxes) =>
        prevBoxes?.map((b: RepackBox) =>
          b.id === updatedBox.id ? updatedBox : b
        )
      );

      if (item.repackCard) {
        setRows((prev: Card[]) => [...prev, item.repackCard]);
      } else if (item.repackTicket) {
        setAllTickets((prevTickets: RepackTicket[]) =>
          prevTickets.map((ticket) =>
            ticket.publicId === item?.repackTicket?.publicId
              ? { ...ticket, repackItemId: undefined, repackItem: undefined }
              : ticket
          )
        );
      }

      newSnackbarMessage("Item deleted successfully!", "success");
    } else {
      newSnackbarMessage("Failed to delete item.", "error");
    }
  };

  const handleCreateBox = async () => {
    if (!selectedBoxType) {
      newSnackbarMessage("Please select a repack type.", "error");
      return;
    }

    try {
      const type = boxTypes.find(
        (type: RepackBoxType) => type.id === selectedBoxType
      );
      if (!type) {
        newSnackbarMessage("Invalid repack type selected.", "error");
        return;
      }
      const response = await CreateNewBox(user, type, seriesId);

      if (response.ok) {
        newSnackbarMessage("Box created successfully!", "success");
        const newBox = await response.json();
        const newBoxes = [...(boxes || []), newBox.result];
        setBoxes(newBoxes);
      } else {
        newSnackbarMessage("Failed to create box.", "error");
      }
    } catch (error) {
      console.error("Failed to create box:", error);
      newSnackbarMessage("Error creating box.", "error");
    }
  };

  const handleAddToCase = async () => {
    if (isAddingToCase) return;

    if (!selectedCase) {
      newSnackbarMessage("No case selected.", "error");
      return;
    }

    try {
      setIsAddingToCase(true);
      if (!selectedBox) {
        newSnackbarMessage("No box selected.", "error");
        return;
      }
      const items = selectedBox.items ?? [];
      const response = await UpdateRepackBox(
        selectedBox,
        items,
        user,
        selectedCase.publicId
      );
      if (response.ok) {
        newSnackbarMessage("Box added to case successfully!", "success");
        const updatedBoxes = boxes?.filter(
          (b: RepackBox) => b.id !== selectedCase.id
        );
        setBoxes(updatedBoxes);
      } else {
        newSnackbarMessage("Failed to add box to case.", "error");
      }
    } catch (error) {
      console.error("Failed to add box to case:", error);
      newSnackbarMessage("Error adding box to case.", "error");
    } finally {
      setIsAddingToCase(false);
    }
  };

  const handleDeleteRepackBox = async () => {
    const prevBox = selectedBox;
    if (!prevBox) {
      newSnackbarMessage("No box selected.", "error");
      return;
    }
    const response = await DeleteRepackBox(selectedBox, user);
    if (response.ok) {
      const removedItemsJson = await response.json();

      newSnackbarMessage("Box deleted successfully!", "success");

      if (removedItemsJson && typeof removedItemsJson === "object") {
        const {
          CardIds: removedCardIds = [],
          TicketIds: removedTicketIds = [],
        } = removedItemsJson;
        setRows((prev) => [...prev, ...removedCardIds]);
        setExpandedRowIds([]);
        setSelectedBox(null);
        setTickets((prev: RepackTicket[]) => [...prev, ...removedTicketIds]);
        setBoxes(boxes?.filter((b: RepackBox) => b.id !== prevBox.id));
      } else {
        console.error(
          "removedItems is undefined or not an object",
          removedItemsJson
        );
      }
    } else {
      newSnackbarMessage("Failed to delete box.", "error");
    }
  };

  const handleRowClick = (params: GridRowParams) => {
    if (params.row.id === selectedBox?.id) {
      setExpandedRowIds([]);
      setSelectedBox(null);
      setBox(null);
      return;
    }

    const b = boxes?.find((rb: RepackBox) => rb.id === params.id);
    if (!b) return;
    setSelectedBox(params.row);
    setExpandedRowIds([params.id]);
  };

  const handleRowExpandChange = (ids: GridRowId[]) => {
    if (ids.length === 1) {
      const expandedId = ids[0];
      const b = boxes?.find((rb: RepackBox) => rb.id === expandedId);

      setExpandedRowIds([expandedId]);
      if (b) {
        setSelectedBox(b);
        setBox(b);
      }
    } else if (ids.length === 0) {
      setExpandedRowIds([]);
      setSelectedBox(null);
      setBox(null);
    } else if (ids.length === 2) {
      const expandedId = ids[1];
      const b = boxes?.find((rb: RepackBox) => rb.publicId === expandedId);

      setExpandedRowIds([expandedId]);
      newSnackbarMessage(
        "Please close the other box before opening this one.",
        "warning"
      );
      if (b) {
        setSelectedBox(b);
        setBox(b);
      } else {
        console.error("Box not found");
        newSnackbarMessage("Box not found.", "error");
      }
    }
  };

  const columns: GridColDef[] = [
    {
      field: "rowNumber",
      headerName: "#",
      width: 100,
      renderCell: (params) =>
        params.api.getSortedRowIds().indexOf(params.id) + 1,
    },
    {
      field: "repackBoxType",
      headerName: "Repack Type",
      width: 100,
      valueGetter: (params) => params.row.repackBoxType?.name || "",
    },
    {
      field: "comp",
      headerName: "Comp",
      width: 100,
      type: "number",
      valueGetter: (params) => {
        const items = params.row.repackItems || [];
        return items.reduce((acc: number, item: RepackItem) => {
          if (item.repackCard?.comp) {
            return acc + Number(item.repackCard.comp);
          }
          if (item.repackTicket?.cost) {
            return acc + Number(item.repackTicket.cost);
          }
          return acc;
        }, 0);
      },
    },
    {
      field: "totalCost",
      headerName: "Total Cost",
      width: 100,
      type: "number",
      valueGetter: (params) => {
        const items = params.row.repackItems || [];
        return items.reduce((acc: number, item: RepackItem) => {
          if (item.repackCard?.totalCost) {
            return acc + Number(item.repackCard.totalCost);
          }
          if (item.repackTicket?.cost) {
            return acc + Number(item.repackTicket.cost);
          }
          return acc;
        }, 0);
      },
    },
    {
      field: "difference",
      headerName: "Difference",
      width: 100,
      type: "number",
      valueGetter: (params) => {
        const items = params.row.repackItems || [];
        return items.reduce((acc: number, item: RepackItem) => {
          if (item.repackCard) {
            const comp = Number(item.repackCard.comp || 0);
            const cost = Number(item.repackCard.totalCost || 0);
            return acc + (comp - cost);
          }
          return acc;
        }, 0);
      },
    },
    {
      field: "containsUnicorn",
      headerName: "Unicorn",
      width: 100,
      valueGetter: (params) =>
        params.row.repackBoxType?.name === "Unicorn" ? "Yes" : "No",
    },
    {
      field: "datesActive",
      headerName: "Dates Active",
      width: 150,
      valueGetter: (params) => {
        const dateStr = params.row.datesActive?.lowerBound;
        if (!dateStr) return "";
        const date = new Date(dateStr);
        return date.toLocaleDateString(undefined, {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        });
      },
    },
  ];

  const itemColumns: GridColDef[] = [
    {
      field: "id",
      headerName: "Item Id",
      width: 100,
      valueGetter: (params) => {
        const item = params.row;
        return item.id;
      },
    },
    {
      field: "name",
      headerName: "Name",
      width: 250,
      valueGetter: (params) => {
        const item = params.row;
        const cardName = item.repackCard?.name || "";
        const ticketName = item.repackTicket?.repackTicketType?.name || "";

        if (item.repackCard && item.repackTicket) {
          return `${cardName} (with ${ticketName})`;
        }

        return cardName || ticketName || "Unknown Item";
      },
    },
    {
      field: "comp",
      headerName: "Comp",
      width: 90,
      valueGetter: (params) => {
        const item = params.row;
        const cardComp = Number(item.repackCard?.comp || 0);
        const ticketCost = Number(item.repackTicket?.cost || 0);
        return cardComp + ticketCost;
      },
    },
    {
      field: "totalCost",
      headerName: "Total Cost",
      width: 90,
      valueGetter: (params) => {
        const item = params.row;
        const cardCost = Number(item.repackCard?.totalCost || 0);
        const ticketCost = Number(item.repackTicket?.cost || 0);
        return cardCost + ticketCost;
      },
    },
    {
      field: "type",
      headerName: "Type",
      width: 150,
      editable: true,
      valueGetter: (params) => mapRepackItemTypeName(params.row),
      renderEditCell: (params) => (
        <Autocomplete
          fullWidth
          value={selectedItemType}
          onChange={(event, newValue) => {
            if (newValue) {
              setSelectedItemType(newValue as RepackItemType);
              params.api.setEditCellValue({
                id: params.id,
                field: params.field,
                value: newValue,
              });
            }
          }}
          inputValue={selectedItemType?.name || ""}
          onInputChange={(event, newInputValue) => {
            const matchingType = itemTypes.find(
              (type) => type.name.toLowerCase() === newInputValue.toLowerCase()
            );

            if (matchingType) {
              setSelectedItemType(matchingType);
            } else {
              setSelectedItemType({
                id: 0,
                name: newInputValue,
              });
            }
          }}
          options={itemTypes}
          getOptionLabel={(option) => {
            return typeof option === "string" ? option : option.name;
          }}
          renderInput={(params) => (
            <TextField {...params} variant="outlined" fullWidth />
          )}
          renderOption={(props, option) => (
            <li {...props}>
              {typeof option === "string" ? option : option.name}
            </li>
          )}
          freeSolo
          filterOptions={(options, params) => {
            const filtered = options.filter((option) =>
              option.name
                .toLowerCase()
                .includes(params.inputValue.toLowerCase())
            );

            if (
              params.inputValue !== "" &&
              !filtered.some((item) => item.name === params.inputValue)
            ) {
              filtered.push({ id: 0, name: `Add "${params.inputValue}"` });
            }

            return filtered;
          }}
          onBlur={() => handleUpdateRepackItems(params.row)}
        />
      ),
    },
    {
      field: "difference",
      headerName: "Difference",
      width: 90,
      valueGetter: (params) => mapRepackItemDifference(params.row),
    },
    {
      field: "attachedTicket",
      headerName: "Attached Ticket",
      width: 150,
      valueGetter: (params) => {
        const ticket = params.row.repackTicket;
        if (!ticket) return "None";
        return `${ticket.repackTicketType?.name || "Unknown"} ($${
          ticket.cost
        })`;
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 50,
      renderCell: (params) => (
        <Button
          color="secondary"
          onClick={() => handleDeleteItem(params.row, user)}
        >
          <DeleteIcon />
        </Button>
      ),
    },
  ];

  const getDetailPanelContent = () => {
    const rowItems = box?.repackItems ?? [];

    if (!rowItems.length) {
      return (
        <Grid
          container
          justifyContent="center"
          alignItems="flex"
          flexDirection="column"
        >
          <Grid item padding={2}>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="50%"
            >
              {boxLoading && <CircularProgress />}
              {!boxLoading && (
                <Typography variant="h6" color="textSecondary">
                  There are no items in this box yet
                </Typography>
              )}
            </Box>
          </Grid>
          <Grid item>
            <Button variant="text" onClick={handleDeleteRepackBox}>
              <DeleteIcon />
              Delete Box
            </Button>
          </Grid>
        </Grid>
      );
    }

    return (
      <Grid>
        <DataGridPro
          getRowId={(row) => row.id}
          rows={rowItems}
          columns={itemColumns}
          processRowUpdate={(newRow) => {
            return handleUpdateRepackItems(newRow);
          }}
          onProcessRowUpdateError={(error) => {
            console.error("Error processing item update:", error);
            newSnackbarMessage("Error processing item update.", "error");
          }}
        />
        <Divider />
        <Button variant="text" onClick={handleDeleteRepackBox}>
          <DeleteIcon />
          Delete Box
        </Button>
      </Grid>
    );
  };

  const CustomToolbar = () => (
    <GridToolbarContainer>
      <Grid
        container
        spacing={2}
        alignItems="center"
        justifyContent="space-between"
      >
        <Grid item style={{ flexGrow: 1 }}>
          <Select
            fullWidth
            id="select-repack-box-type"
            value={selectedBoxType}
            onChange={handleSelectedBoxTypeChange}
            displayEmpty
          >
            {!boxTypes?.length ? (
              <MenuItem value="" disabled>
                No box types available
              </MenuItem>
            ) : (
              boxTypes.map((type: RepackBoxType) => (
                <MenuItem key={type.publicId} value={type.id}>
                  {type.name || "Unknown Type"}
                </MenuItem>
              ))
            )}
          </Select>
        </Grid>
        <Grid item>
          <Button variant="text" onClick={handleCreateBox}>
            Add New Box
          </Button>
          <Button
            variant="text"
            onClick={handleAddToCase}
            disabled={isAddingToCase}
          >
            {isAddingToCase ? "Adding to Case..." : "Add To Selected Case"}
          </Button>
        </Grid>
      </Grid>
    </GridToolbarContainer>
  );

  return (
    <CollapsiblePaper
      title="Staged Boxes Preview"
      closeTitle="Close Preview"
      openTitle="Open Preview"
    >
      <div style={{ height: "auto", minHeight: "400px", width: "100%" }}>
        <DataGridPro
          getRowId={(row) => row.id}
          rows={boxRows}
          columns={columns}
          getDetailPanelContent={getDetailPanelContent}
          getDetailPanelHeight={() => 250}
          detailPanelExpandedRowIds={expandedRowIds}
          onDetailPanelExpandedRowIdsChange={handleRowExpandChange}
          disableMultipleRowSelection={true}
          onRowClick={handleRowClick}
          processRowUpdate={(newRow) => {
            return handleUpdateRepackBox(newRow);
          }}
          onProcessRowUpdateError={(error) => {
            console.error("Error processing box update:", error);
            newSnackbarMessage("Error processing box update.", "error");
          }}
          components={{ Toolbar: CustomToolbar }}
        />
      </div>
    </CollapsiblePaper>
  );
};

export default StagedRepackPreview;
